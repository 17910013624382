.panel_wrapper {
  border-right: 2px solid rgba(55, 150, 246, 0.1);
  max-width: 158px;
  margin-top: -80px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;
}

.panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  .logo_panel {
    margin-top: 32px;
    margin-bottom: 68px;
    img {
      background-size: 100% 100%;
      height: 28px;
      width: 94px;
    }
  }
  .block_link {
    display: flex;
    flex-flow: column;
    a {
      width: 64px;
      height: 64px;
      background: rgba(54, 78, 101, 0.2);
      font-size: 18px;
      border-radius: 10px;
      text-decoration: none;
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.order-3 {
        order: 3;
      }
      &.order-2 {
        order: 2;
      }
      &.order-1 {
        order: 1;
      }
      &:hover {
        background: #3796f6;
      }
    }
    .is-active {
      box-shadow: 0 4px 8px 0 rgba(24, 113, 204, 0.25);
      background: #3796f6;
    }
  }
}

.count-messages {
  position: relative;
}
.customer-statements{
  background: transparent;
  &:hover{
    background: transparent;
    .inactive{
      opacity: 0;
    }
    .active{
      opacity: 1;
    }
  }
  .active, .inactive{
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s linear;
  }
  .inactive{
    opacity: 1;
  }
  .active{
    opacity: 0;
  }
}
.is-active{
  .inactive{
    opacity: 0;
  }
  .active{
    opacity: 1;
  }
}

.unread-messages {
  position: absolute;
  top: -15px;
  right: -22px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 99;
  background-color: #fff;
  &--count {
    margin: 0 auto;
    background: #e7f3ff;
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #3796f6;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
  }
  &--warning {
    margin: 0 auto;
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #3796f6;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
  }
}
