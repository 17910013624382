.sales_list_content_page {
    background: #ffffff;
    padding: 36px 40px 10px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px;
    .table_panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        > div {
            > input {
                margin-right: 24px;
                height: 40px;
                width: 280px;
                border: 1px solid rgba(32, 69, 105, 0.3);
                border-radius: 4px;
                padding: 13px 12px;
                box-sizing: border-box;
                font-family: "MontRegular";
                font-size: 14px;
                &::placeholder {
                    opacity: 0.5;
                    color: #204569;
                    font-size: 14px;
                    letter-spacing: 0;
                }
            }
            .select_component .select__control .select__single-value {
                padding-left: 10px !important;
            }
            .select__control {
                width: 208px;
                min-height: 40px;
            }
        }
        a {
            margin-right: 24px;
            color: #3796f6;
            text-transform: uppercase;
            font-size: 10px;
            font-family: "MontSemiBold", sans-serif;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .order_page_table {
        .transactions_columns {
            .table_row {
                & > :first-child {
                    width: 20%;
                    a {
                        font-family: "MontSemiBold", sans-serif;
                        color: #3796f6;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
                & > :nth-child(2) {
                    width: 15%;
                    a {
                        font-family: "MontSemiBold", sans-serif;
                        color: #3796f6;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
                & > :nth-child(3) {
                    width: 20%;
                    font-family: "MontSemiBold", sans-serif;
                    color: #204569;
                }
                & > :nth-child(4) {
                    width: 20%;
                }
                & > :nth-child(5) {
                    width: 15%;
                    display: flex;
                    font-family: "MontSemiBold", sans-serif;
                    align-items: center;

                    .status-name {
                        display: flex;
                    }
                    .request-status {
                        margin-top: 5px;
                        color: #204569;
                        opacity: 0.5;
                        font-size: 10px;
                        font-family: "MontSemiBold", sans-serif;
                    }

                    .status_block {
                        padding: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: "MontSemiBold", sans-serif;
                        font-size: 10px;
                        line-height: 13px;
                        margin-left: 16px;
                        border-radius: 14px;
                        img {
                            margin-right: 6px;
                        }
                    }
                    .overdue {
                        color: #e44b4b;
                        background-color: rgba(228, 75, 75, 0.1);
                    }
                    .paid {
                        color: #2cc197;
                        background-color: rgba(44, 193, 151, 0.1);
                    }
                    .partial {
                        background-color: rgba(253, 164, 92, 0.1);
                        color: #fda45c;
                    }
                    .new {
                        background-color: rgba(55, 150, 246, 0.1);
                        color: #3796f6;
                    }
                    .blue-dot {
                        background: #3796f6;
                        border-radius: 50%;
                        width: 6px;
                        height: 6px;
                        display: flex;
                        margin-left: 2px;
                        margin-bottom: 6px;
                    }
                }
                & > :last-child {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .blue_dot {
                        height: 6px;
                        width: 6px;
                        background-color: #3796f6;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    .pagination_info_wrapper {
        border-top: 1px solid rgba(32, 69, 105, 0.25);
        padding-bottom: 20px;
    }
}

.sales_order_page_inner {
    display: flex;
    flex-flow: column;

    .add-btn {
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        color: #3796f6;
        line-height: 16px;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }

    .mt40 {
        margin-top: 40px;
    }

    .content_page {
        background: #ffffff;
        padding: 36px 40px 10px;
        margin-bottom: 72px;
        box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
        border-radius: 10px;
    }
    .title_block {
        margin-bottom: 32px;
        color: #204569;
        font-family: "MontSemiBold", sans-serif;
        font-size: 16px;
        line-height: 24px;
    }
    .general_info {
        border-bottom: 1px dashed rgba(32, 69, 105, 0.25);
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        .block_field {
            margin-bottom: 32px;
            display: flex;
            flex-direction: row;
            > div {
                display: flex;
                flex-direction: column;
                span {
                    font-family: "MontSemiBold", sans-serif;
                    font-size: 10px;
                    line-height: 13px;
                    padding-bottom: 8px;
                    color: #204569;
                }
                input {
                    padding: 0 16px;
                    box-sizing: border-box;
                    border: 1px solid rgba(32, 69, 105, 0.3);
                    border-radius: 4px;
                    height: 48px;
                    font-family: "MontRegular", sans-serif;
                    font-size: 14px;
                    &::placeholder {
                        color: rgba(32, 69, 105, 0.5);
                    }
                }
            }
            > div:first-of-type {
                margin-right: 32px;
                input {
                    width: 120px;
                }
            }
            > div:nth-child(2) {
                margin-right: 32px;
                input {
                    width: 432px;
                }
            }
            > div:nth-child(3) {
                margin-right: 32px;
                input {
                    width: 120px;
                }
            }
        }
        .block_field_more {
            > div:first-of-type {
                margin-right: 32px;
                input {
                    width: 130px;
                }
            }
            > div:nth-child(2) {
                margin-right: 32px;
                position: relative;

                input {
                    width: 183px;
                    padding: 0 32px 0 16px;
                }
                > p {
                    position: absolute;
                    right: 16px;
                    top: 35px;
                    opacity: 0.5;
                    color: #204569;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
            > div:nth-child(3) {
                margin-right: 32px;
                .select_wrapper {
                    width: 244px;
                }
                .select_component {
                    .select__menu .select__menu-list .select__option {
                        padding: 8px 16px;
                    }
                    .select__control {
                        .select__single-value {
                            min-height: 48px;
                            height: 48px;
                            padding-left: 16px !important;
                            align-items: center;
                            display: flex;
                        }
                    }
                }
            }
        }
    }
    .wrapper_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 32px 0;
        padding-top: 40px;
        border-top: 1px solid rgba(32, 69, 105, 0.25);
        > div {
            display: flex;
            align-items: center;
            button,
            div {
                margin-right: 24px;
                font-family: "MontBold", sans-serif;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                width: 168px;
                border-radius: 4px;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }

    .products {
        .mb0 {
            margin-bottom: 0;
        }
        .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
        }
        .add-more {
            font-family: "MontSemiBold", sans-serif;
            font-size: 10px;
            color: #3796f6;
            line-height: 16px;
            text-transform: uppercase;
            &:hover {
                opacity: 0.7;
            }
        }
        .btn-wrapper-add {
            display: flex;
            align-items: center;
            > div {
                margin-left: 895px;
                font-size: 10px;
                font-family: "MontBold";
                color: #204569;
            }
        }
        .block {
            display: flex;
            // margin-bottom: 32px;

            > div {
                margin-right: 32px;
                display: flex;
                flex-direction: column;
                span {
                    font-family: "MontSemiBold", sans-serif;
                    font-size: 10px;
                    line-height: 13px;
                    padding-bottom: 8px;
                    color: #204569;
                }
                input {
                    padding: 0 16px;
                    box-sizing: border-box;
                    border: 1px solid rgba(32, 69, 105, 0.3);
                    border-radius: 4px;
                    height: 48px;
                    font-family: "MontRegular", sans-serif;
                    font-size: 14px;
                    &::placeholder {
                        color: rgba(32, 69, 105, 0.5);
                    }
                }
            }
            > button {
                display: flex;
                margin-top: 40px;
            }
            > div:first-of-type {
                input {
                    width: 584px;
                }
            }
            > div:nth-child(2) {
                input {
                    width: 106px;
                }
            }
            > div:nth-child(3) {
                input {
                    width: 106px;
                }
            }
            > div:nth-child(4) {
                input {
                    width: 106px;
                }
            }
            > div:nth-child(5) {
            }
        }
    }
    .calendar-input {
        border: 1px solid rgba(32, 69, 105, 0.3);
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        height: 48px;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        width: 184px;
        &:hover {
            border: 1px solid rgba(32, 69, 105, 0.3);
        }
        > input {
            padding-left: 20px;
            border: none !important;
            font-size: 14px;
            font-family: "MontRegular", sans-serif;
            color: #204569;
            box-sizing: border-box;
            width: 130px;
        }
        > img {
            padding-right: 16px;
        }
    }
}
