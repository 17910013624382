.multi__select-dropdown {
  .dropdown__elem {
    border: 1px solid rgb(32, 69, 105);
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;
    align-items: center;
    position: relative;
    &--icon {
      &.up {
        transform: rotate(180deg);
      }
    }
    p {
      color: #204569;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  &.disabled {
    .dropdown__elem {
      cursor: not-allowed;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 14px 16px;
      align-items: center;
      position: relative;
      &--icon {
        opacity: 0.3;
        &.up {
          transform: rotate(180deg);
        }
      }
      p {
        color: rgba(32, 69, 105, 0.5);
        font-size: 14px;
        letter-spacing: 0;
      }
    }
  }

  ul.dropdown__list {
    display: flex;
    margin: 0;
    padding-left: 0;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    height: auto;
    max-height: 228px;
    overflow-y: scroll;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.25);
    position: absolute;
    background-color: #fff;
    width: 592px;
    z-index: 9999;
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    .measure {
      display: flex;
      order: 998;
      border-bottom: 1px dashed rgba(32, 69, 105, 0.25);
    }

    /* Track */

    /* Handle */
    &::-webkit-scrollbar-thumb {
      width: 3px;
      border-radius: 1.5px;
      background-color: #204569;
    }
    li {
      list-style: none;
      display: flex;
      margin: 8px 0;
      order: 999;
      &.selected {
        order: 1;
        &:last-child {
          border-bottom: 1px dashed hwb(210 13% 59%);
        }
      }
      > label {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 12px;
          line-height: 16px;
          color: #204569;
          margin-left: 8px;
          text-transform: capitalize;
        }
      }
      .label {
        border-radius: 14px;
        padding: 6px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }
        margin-left: 8px;
        &-new {
          font-size: 10px;
          font-weight: 600;
          color: #3796f6;
          background-color: rgba(55, 150, 246, 0.1);
        }
        &-partial {
          font-size: 10px;
          font-weight: 600;
          color: #fda45c;
          background-color: rgba(253, 164, 92, 0.1);
        }
      }
    }
  }
}

.empty__list {
  color: #204569;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
