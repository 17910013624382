.custumer-edit{
    &-form{
      background: #ffffff;
      padding: 32px 40px ;
      margin-bottom: 32px;
      box-shadow: 0 4px 16px 0 rgba(154,166,178,0.15);
      border-radius: 10px;
     
      .select__single-value{
          padding-left: 16px !important;
      }
      .select__value-container.select__value-container--has-value{
          display: flex;
          align-items: center;
          padding-left: 16px!important;
          height: 48px;
      }
      .select__menu-list,
      .select__menu{
        padding-left: 5x;
      }
      .select_component{
          input{
            padding-left: 5px!important;
          }
        
      }

      .select__value-container{
          >div + div{
              margin: 0;
              padding: 0;
          }
      }
      

      .select_component .select__control .select__value-container{
          height: 48px;
      }
      .select__placeholder{
        display: flex;
        align-items: center;
        padding-left: 16px!important;
       
        height: 48px;
      }
      .blue_btn_bg,
      .blue_btn_unactive{
        width: 162px!important;
        height: 48px;
        font-size: 16px;
      }
      .clinic-name,
      .email{
          //max-width: 200px;
          width: 30%;
          
      }
      .select__control{
          height: 48px;
      }
      .region,
      .phone,
      .province,
      .director,
      .phone,
      .district,
      .sales{
        //  max-width: 216px;
          width: 22%;
      }
      .block_edit_add_field{
          .full-width{
            margin-left: 32px;
            &:first-child{
                margin-left: 0;
            }
          }

          &:nth-child(2){
            padding-bottom: 32px;
            border-bottom: 1px solid rgba(32, 69, 105, .5);
          }
        
          
      }
     
    }
    // &-container{
    //     max-width: 980px;
    // }
    &-title{
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      font-family: 'MontSemiBold', sans-serif;
      margin-bottom: 32px;
    }

  
  }
  
  .block_edit_add_field {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    > div {
        display: flex;
        flex-flow: column;
        span {
            font-family: "MontSemiBold", sans-serif;
            font-size: 10px;
            line-height: 13px;
            padding-bottom: 8px;
            color: #204569;
        }
        input {
            padding: 0 16px;
            box-sizing: border-box;
            border: 1px solid rgba(32, 69, 105, 0.3);
            border-radius: 4px;
            height: 48px;
            font-family: "MontRegular", sans-serif;
            font-size: 14px;
            &::placeholder {
                color: rgba(32, 69, 105, 0.5);
            }
        }
    }
    .select_block {
        width: 208px;
    }
    > div:first-of-type {
        input {
            width: 352px;
        }
    }
    > div:last-of-type:not(:first-of-type) {
        position: relative;
        input {
            width: 208px;
            padding: 0 30px 0px 16px;
            box-sizing: border-box;
        }
        p {
            position: absolute;
            right: 16px;
            top: 36px;
            opacity: 0.5;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .full-width {
        width: 100%;
        > input {
            width: 100% !important;
        }
    }
  }

  .customers_inner_clinicName{
      color: #204569;
      font-size: 32px;
      line-height: 42px;
  }