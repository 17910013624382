.subscriptions_page_inner {
  display: flex;
  flex-flow: column;
  .title_block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .link_req{
      display: flex;
      a{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: #3796F6;
        font-family: "MontSemiBold",sans-serif;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        img{
          margin-right: 8px;
          margin-top: -2px;
        }
      }

    }
    .title_page{
      padding: 0;
    }
    >div:nth-child(2){
      display: flex;
      align-items: flex-end;
      button{
        color: #3796F6;
        font-family: "MontSemiBold",sans-serif;
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        img{
          margin-right: 4px;
        }
      }
    }

  }
  .content_page {
    background: #ffffff;
    padding: 32px 40px 32px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px;
  }
  .descriptions{
    margin-bottom: 32px;
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  .general{
    display: flex;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px dashed rgba(32, 69, 105, 0.25);
    >div:nth-child(1){
      max-width: 15%;
    }
    >div:nth-child(2){
      max-width: 40%
    }
  }
  .product{
    display: flex;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px dashed rgba(32, 69, 105, 0.25);
    >div:nth-child(1){
      max-width: 52%;
    }
    >div:nth-child(2){
      max-width: 12%;
    }

    >div:nth-child(3){
      max-width: 12%;
    }
    >div:nth-child(4){
      max-width: 12%;
    }
    >div:nth-child(5){
      max-width: 12%;
      margin-right: 0;
      input{
        padding: 0 32px 0 16px;
      }
    }
  }
  .input_block{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    width: 100%;
    p{
      position: absolute;
      right: 16px;
      top: 34px;
      opacity: 0.5;
      color: #204569;
      font-size: 14px;
      line-height: 20px;
    }
    span{
      color: #204569;
      font-family: "MontSemiBold",sans-serif;
      font-size: 10px;
      line-height: 13px;
      margin-bottom: 8px;
      display: flex;
    }
    input{
      width: 100%;
      height: 48px;
      border: 1px solid rgba(32, 69, 105, 0.1);
      border-radius: 4px;
      padding: 0 16px;
      box-sizing: border-box;
      color: #204569;
      font-family: "MontRegular",sans-serif;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .btn_block{
    display: flex;
    button{
      height: 48px;
      width: 168px;
      font-size: 14px;

      margin-right: 24px;
    }
  }
}

.subscription_inner_dialog{
  .title{
    color: #204569;
    font-family: "MontSemiBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
    margin-bottom: 32px;
  }
  .descriptions {
    font-size: 14px;
    line-height: 22px;
    color: #204569;
    span{
      font-family: "MontSemiBold", sans-serif !important;

    }
  }
  .block_field {
    margin-top: 24px;
    position: relative;
    display: flex;
      flex-flow: column;
    width: 104px;
      span {
        font-family: "MontSemiBold", sans-serif;
        font-size: 10px;
        line-height: 13px;
        padding-bottom: 8px;
        color: #204569;
      }
      input {
        padding: 0 32px 0 16px;
        box-sizing: border-box;
        border: 1px solid rgba(32, 69, 105, 0.3);
        border-radius: 4px;
        height: 48px;
        font-family: "MontRegular", sans-serif;
        font-size: 14px;
        &::placeholder {
          color: rgba(32, 69, 105, 0.5);
        }
      }
      p{
        position: absolute;
        right: 16px;
        top: 34px;
        opacity: 0.5;
        color: #204569;
        font-size: 14px;
        line-height: 20px;
      }

  }
  .btn_wrapper {
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    display: flex;
    padding-top: 32px;
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}