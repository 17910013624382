.customers_page {
  display: flex;
  flex-flow: column;
  .pagination_info_wrapper {
    padding-bottom: 20px;
    border-top: 1px solid rgba(32, 69, 105, 0.25);
  }
  h3 {
    padding: 30px 0;
    border-top: 1px solid rgba(32, 69, 105, 0.25);
    margin-top: 18px;
  }
  .content_page {
    background: #ffffff;
    padding: 32px 40px 10px;
    margin-bottom: 72px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px;
  }
  .table_panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    > input {
      height: 40px;
      width: 280px;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      padding: 13px 12px;
      box-sizing: border-box;
      font-family: 'MontRegular';
      font-size: 14px;
      &::placeholder {
        opacity: 0.5;
        color: #204569;
        font-size: 14px;
        letter-spacing: 0;
        font-family: 'MontRegular';
      }
    }
    button {
      margin-right: 24px;
      color: #3796f6;
      text-transform: uppercase;
      font-size: 10px;
      font-family: 'MontSemiBold', sans-serif;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }
  .approval_table {
    .transactions_columns {
      .table_row {
        height: auto;
        flex-flow: column;
        .row {
          width: 100%;
          display: flex;
          height: 60px;
          align-items: center;
          & > :first-child {
            width: 20%;
          }
          & > :nth-child(2) {
            width: 19%;
          }
          & > :nth-child(3) {
            width: 29%;
          }
          & > :nth-child(4) {
            width: 17%;
          }
          & > :nth-child(5) {
            width: 17%;
          }

          & > :last-child {
            width: 15%;
            display: flex;
            button {
              margin-right: 24px;
              text-transform: uppercase;
              font-family: 'MontSemiBold', sans-serif;
              font-size: 10px;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
  .active_customers_table {
    .transactions_columns {
      .table_row {
        & > :first-child {
          width: 20%;
          a {
            color: #3796f6;
            font-family: 'MontSemiBold', sans-serif;
          }
        }
        & > :nth-child(2) {
          width: 20%;
        }
        & > :nth-child(3) {
          width: 30%;
        }
        & > :nth-child(4) {
          width: 22%;
        }
        & > :nth-child(5) {
          width: 17%;
        }
        & > :last-child {
          width: 8%;
        }
        & .edit-btn button {
          text-transform: uppercase;
          line-height: 16px;
          color: #3796f6;
          font-size: 10px;
        }
      }
    }
  }
  .btn_arrow_open {
    img {
      transform: rotate(180deg);
    }
  }
  .btn_arrow {
    img {
      transition: all 0.3s linear;
    }
  }
}

.pagination_info_wrapper {
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info {
    opacity: 0.5;
    color: #204569;
    font-family: 'MontLight', sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
  }
}

.tab_customers {
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  button {
    color: rgba(32, 69, 105, 0.3);
    font-family: 'MontSemiBold', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-right: 32px;
    position: relative;
    &:last-of-type {
      margin-right: auto;
    }
    &:hover {
      color: rgba(32, 69, 105, 1);
    }
    span {
      position: absolute;
      border-radius: 50%;
      top: 1px;
      right: -7px;
      height: 6px;
      width: 6px;
      background-color: #3796f6;
    }
  }
  > span {
    color: #3796f6;
    font-size: 10px;
    font-family: 'MontSemiBold';
    cursor: pointer;
    margin-left: 32px;
  }
  .active {
    color: rgba(32, 69, 105, 1);
  }
}

.expansion .expansion_panel .head {
  cursor: default !important;
}

.expansion .expansion_panel .head .MuiIconButton-root {
  cursor: pointer;
}


.regional_managers_edit_add_dialog .block_edit_add_field.location > div{
max-width: 176px;
width: 100%;
}

.region_item{
  input{
    max-width: 176px;
    width: 100%;
  }
}


.regional_managers_edit_add_dialog{
  .form-email{
   
      max-width: 384px!important;
      width: 100%;

      input{
        width: 100% !important;
      }
    
 
  }
  .form-phone{
    max-width: 176px!important;
    width: 100%;
    input{
      width: 100% !important;
    }
  
  }
}



.regional_managers_edit_add_dialog{
  .select__value-container.select__value-container--has-value,
  .select__value-container{
    height: 48px;
    
  }
  .select__control{
    input{
      padding-left: 16px !important;
    }
  }
  .form-edit-container{
    display: flex!important;
    flex-direction: row!important;
    justify-content: space-between;
    max-width: 280px;
    width: 100%;
  }
  .form-email-add{
   
    max-width: 280px!important;
    width: 100%;

    input{
      width: 100% !important;
    }
  

}
.form-password-add,
.form-phone-add{

  max-width: 124px;
  width: 100%;
  input{
    margin-top: 3px;
    max-width: 124px;
    width: 100%;
  }
}



}

.regional_managers_edit_add_dialog{
  .select__menu,
  .select__menu-list{
      height: 150px!important;
      padding-left: 5px;
  }
}
