.error-text {
  bottom: -25px;
  display: block;
  color: #e44b4b !important;
  position: absolute;
  left: 0;
  font-size: 10px;
  line-height: 13px;
}

.block-input {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  .error {
    display: none;
  }
  &.error_border,
  &.value.error_border {
    position: relative;
    // input {
    //     border: 1px solid #e44b4b !important;
    //     color: #e44b4b !important;
    // }
  }
  input {
    font-family: 'MontRegular', sans-serif;
    background: #fff;
    display: block;
    height: 48px;
    width: 100%;
    font-size: 14px;
    padding: 0 16px;
    color: #204569;
    border-radius: 4px;
    border: 1px solid rgba(32, 69, 105, 0.3);
    box-sizing: border-box;
    &:focus {
      border: 1px solid #204569;
    }
    &::placeholder {
      font-size: 14px;
      color: rgba(32, 69, 105, 0.5);
      line-height: 20px;
    }
  }
}

.currency-block-value {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 !important;
  color: rgba(32, 69, 105, 0.5) !important;
  font-size: 14px !important;
}

input.input-with-currency {
  width: 132px !important;
  padding-right: 44px !important;
}

.calendar-block{
  position: relative;
}