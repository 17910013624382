.default_dialog_root {
  .paper_custom_dialog{
    border-radius: 6px;
    min-width: 720px;
    width: 100%;
    box-shadow: none;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right:23px;
    top: 23px;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      transform: scale(1.2);
    }
  }
}

.dialog_wrapper {
  padding: 48px 64px;
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
}


.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .3rem;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left;
  line-height: 23px;
}
.table-bordered {
  border: 1px solid black;
}
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

#proformaTitle {
  text-align: center;
  vertical-align: middle;
  font-family: "MontBold",sans-serif;
}

 .modelBody .table-bordered tr td {
   border: 1px solid black;
 }
 .table-bordered td, .table-bordered th {
   border: 1px solid #dee2e6;
 }
 .table td, .table th {
   padding: .75rem;
   vertical-align: top;
   border-top: 1px solid #dee2e6;
 }
 th, td, caption {
   font-weight: normal;
   vertical-align: top;
   text-align: left;
 }
.modelBody .table-bordered tr td {
  border: 1px solid black;
}
#clientDesc{
  font-family: "MontBold",sans-serif;

}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

