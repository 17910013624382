.header_wrapper {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid rgba(55, 150, 246, 0.1);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  box-sizing: border-box;
  .name {
    margin-right: 16px;
    color: #204569;
    font-size: 14px;
    line-height: 18px;
  }
  .logout {
    &:hover {
      transform: scale(0.9);
    }
  }
  .logo_head {
    > img {
      height: 1px;
      width: 1px;
    }
  }
  .right-wrapper {
    display: flex;
    align-items: center;
  }
}
