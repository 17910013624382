.customers_statements_page {
  display: flex;
  flex-flow: column;
  font-family: 'MontRegular', sans-serif;
  .content_page {
    background: #ffffff;
    padding: 32px 40px 10px;
    margin-bottom: 32px;
    box-shadow: 0 4px 16px 0 rgba(154, 166, 178, 0.15);
    border-radius: 10px;
  }

  .descriptions {
    margin-bottom: 10px;
    color: #204569;
    font-family: 'MontSemiBold', sans-serif;
    letter-spacing: 0;
    line-height: 24px;
    font-size: 16px;
  }

  .total_table {
    .transactions_columns {
      .table_row {
        & > :nth-child(1) {
          width: 25%;
          span {
            font-family: 'MontSemiBold', sans-serif;
            font-weight: 600;
            font-size: 16px;
          }
        }
        & > :nth-child(2) {
          width: 25%;
        }
        & > :nth-child(3) {
          width: 25%;
          span {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            margin-left: 8px;
            letter-spacing: 0;
          }
          .ok {
            color: #2cc197;
          }
          .up {
            color: #e44b4b;
          }
          .down {
            color: #fda45c;
          }
        }
        & > :nth-child(4) {
          width: 25%;
          display: flex;
          align-items: center;
          span {
            font-family: 'MontSemiBold', sans-serif;
            font-weight: 600;
            font-size: 16px;
            margin-right: 8px;
          }
          p {
            padding: 0;
            display: flex;
            align-items: center;
          }
        }
      }
      .table_body {
        .table_row {
          .row_item {
            font-size: 16px;
            letter-spacing: -0.25px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .customers_table {
    .transactions_columns {
      .table_row {
        & > :nth-child(1) {
          display: flex;
          align-items: center;
          width: 20%;
          a {
            color: #3796f6;
            font-family: 'MontSemiBold', sans-serif;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
          }
          > div {
            margin-left: 8px;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            &:hover {
              > span {
                display: flex;
              }
            }
            > span {
              height: 60px;
              width: 256px;
              border-radius: 8px;
              background-color: #ffffff;
              box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.15);
              display: none;
              position: absolute;
              top: -75px;
              left: calc(50% - 128px);
              text-align: center;
              align-items: center;
              padding: 12px 16px 16px;
              box-sizing: border-box;
              color: #204569;
              font-family: 'MontSemiBold', sans-serif;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 16px;
              &:before {
                content: '';
                position: absolute;
                bottom: -4px;
                left: calc(50% - 2px);
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #ffffff;
              }
            }
          }
        }
        & > :nth-child(2) {
          width: 20%;
        }
        & > :nth-child(3) {
          width: 20%;
        }
        & > :nth-child(4) {
          width: 20%;
          span {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            margin-left: 8px;
            letter-spacing: 0;
          }
          .ok {
            color: #2cc197;
          }
          .up {
            color: #e44b4b;
          }
          .down {
            color: #fda45c;
          }
        }
        & > :nth-child(5) {
          width: 20%;
          display: flex;
          align-items: center;
          span {
            font-family: 'MontSemiBold', sans-serif;
            font-weight: 600;
            font-size: 14px;
            margin-right: 8px;
          }
          p {
            padding: 0;
            display: flex;
            align-items: center;
          }
        }
      }
      .table_body {
        .table_row {
          .row_item {
            font-size: 14px;
            letter-spacing: -0.25px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .pagination_info_wrapper {
    border-top: 1px solid rgba(32, 69, 105, 0.25);
  }

  .pagination-container {
    .pagination-list {
      padding: 32px 0 22px;
    }
  }

  .table_panel {
    display: flex;
    align-items: center;
    padding: 22px 0;
    .search_block {
      margin-right: 24px;
      height: 40px;
      width: 280px;
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      background-color: #ffffff;
      box-sizing: border-box;
      padding-left: 34px;
      display: flex;
      align-items: center;
      position: relative;
      input {
        color: #204569;
        font-family: 'MontRegular', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding-right: 16px;
        &::placeholder {
          opacity: 0.5;
        }
      }
      img {
        position: absolute;
        left: 12px;
        top: calc(50% - 7px);
      }
    }
    .select_wrapper {
      width: 200px;
      height: 40px;
      > div {
        height: 40px;
      }
      .select_component .select__control .select__single-value {
        padding-left: 10px !important;
      }
    }
  }
}

.customers__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > button {
    color: #3796f6;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: right;
  }
}

// Add payment POPUP styles

.addForm {
  > .block_field {
    margin-bottom: 32px;
  }
  .payment-data-row {
    &.three__elements {
      .block_field {
        &.date {
          max-width: 176px;
          width: 100%;
          margin-right: 32px;
          .calendar-input {
            max-width: 176px;
            width: 100%;
            box-sizing: border-box;
            > input {
              width: 140px;
            }
          }
        }
        &.name-block {
          max-width: 176px;
          width: 100%;
          box-sizing: border-box;
          > label {
            margin-left: 0;
            max-width: 176px;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            .currency__label {
              position: absolute;
              right: 10px;
              top: 15px;
              text-transform: uppercase;
              color: #204569;
              opacity: 0.5;
              line-height: 20px;
              font-size: 14px;
            }
            input {
              padding: 0 45px 0 16px;
            }
          }
        }
      }
    }
  }
}
