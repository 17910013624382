.loaderWrapper {
    min-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader_small {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 19px;
}